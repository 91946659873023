import md5 from 'js-md5'
export var SearchList = [
  { labe: '采购订单', code: 'order', type: 'input', placeholder: '编码/名称' },
  { labe: '网上发票号', code: 'onlineInvoiceNumber', type: 'input' },
  { labe: '公司名称', code: 'corporateName', type: 'input', placeholder: '编码/名称' },
  {
    labe: '创建日期',
    code: 'createDate',
    type: 'daterange'
  },
  {
    labe: '网上发票状态',
    code: 'onlineInvoiceStatus',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待匹配发票', value: '0' },
      { label: '已作废', value: '1' },
      { label: '已完成', value: '2' },
      { label: '取消记账', value: '3' },
      { label: '记账失败', value: '4' },
      { label: '待签收', value: '5' },
      { label: '已签收', value: '6' }
    ]
  }
  // {
  //   labe: '付款状态',
  //   code: 'paymentStatus',
  //   type: 'select',
  //   option: [
  //     { label: '全部', value: '' },
  //     { label: '已付款', value: '0' },
  //     { label: '未付款', value: '1' },
  //     { label: '部分付款', value: '2' }
  //   ]
  // }
]
export var SearchData = {
  onlineInvoiceNumber: '',
  corporateName: '',
  // createDate: '',
  strStartDate: '',
  strEndDate: '',
  onlineInvoiceStatus: ''
  // paymentStatus: ''
}
export var menudata = [{
  label: '+创建网上发票',
  action: 'CreateaInvoice',
  id: 1
}]

export var tableField = [
  { label: '网上发票号', code: 'onlineInvoiceNumber', type: 'input', width: '200' },
  { label: '未税总额', code: 'totalNotTaxed', type: 'amount', align: 'right', width: '90' },
  { label: '含税总额', code: 'totalIncludingTax', type: 'amount', align: 'right', width: '90' },
  { label: '总税额', code: 'totalOnlineInvoiceTax', type: 'amount', align: 'right', width: '' },
  { label: '币种', code: 'currency', type: 'input', width: '' },
  // { label: '公司名称', code: 'corporateName', type: 'input', width: '' },
  {
    label: '公司名称',
    code: 'corporateName',
    type: 'function',
    width: '120px',
    handle (index, row) {
      return `${row.corporateCode}-${row.corporateName}`
    }
  },
  {
    label: '开票方',
    code: 'billingParty',
    type: 'function',
    width: '120px',
    handle (index, row) {
      return `${row.billingPartyCode}-${row.billingParty}`
    }
  },
  // { label: '开票方', code: 'billingParty', type: 'input', width: '' },
  { label: '创建日期', code: 'createDate', type: 'input', width: '' },
  {
    label: '发票状态',
    code: 'onlineInvoiceStatus',
    type: 'function',
    width: '',
    handle: (index, data) => {
      const color = md5(data.onlineInvoiceStatus + '').substring(0, 6)
      const label = data.onlineInvoiceStatus === 0 ? '待匹配发票' : data.onlineInvoiceStatus === 1 ? '已作废'
        : data.onlineInvoiceStatus === 2 ? '已完成' : data.onlineInvoiceStatus === 3 ? '取消记账'
          : data.onlineInvoiceStatus === 4 ? '记账失败' : data.onlineInvoiceStatus === 5 ? '待签收' : '已签收'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  // {
  //   label: '付款状态',
  //   code: 'paymentStatus',
  //   type: 'function',
  //   width: '',
  //   handle: (index, data) => {
  //     return data.paymentStatus === 0 ? '已付款' : data.paymentStatus === 1 ? '未付款' : '部分付款'
  //   }
  // },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = []
