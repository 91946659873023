<template>
<!--供应商网上发票列表  -->
    <div class="list">
      <list-search :childData="childData"  @onSearch="search"  @clearSearch="search"></list-search>
      <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
      <global-table :tableField="tableField"  :tableData="tableData" @tableAction="tableAction" :paginationData="paginationData" @pageChange="pageChange"></global-table>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { SearchList, SearchData, tableField, tableData, menudata } from './js/Supplierinvoice'
export default {
  components: Component.components,
  name: 'Supplierinvoice',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      menudata: menudata,
      searchdata: SearchData,
      paginationData: {},
      pageNum: 1,
      pageSize: pageSize,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  mounted () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    const obj = {
      pageSize: this.pageSize,
      pageNum: this.pageNum
    }
    requestForm('/api/reconciliation/invoiceTotal/queryAll', 'post', obj).then((res) => {
      if (res.code === '200') {
        this.tableData = res.data.records
        this.paginationData = { pageSize: pageSize, total: res.data.total }
      }
    })
  },
  methods: {
    buttonClick () {
      this.$router.push({ params: { viewName: 'CreateonlineInvoices' } })
    },
    tableAction: function (index, data, clickBtn) {
      this.$router.push({ params: { viewName: 'SupplierinvoiceDetail' }, query: { id: data.id } })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.strEndDate = this.dateFormate(data.createDate[1])
        this.searchdata.strStartDate = this.dateFormate(data.createDate[0])
      }
      requestForm('/api/reconciliation/invoiceTotal/queryAll', 'post', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      requestForm('/api/reconciliation/invoiceTotal/queryAll', 'post', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
